import React, { useState, useEffect, useRef } from "react";
import { Manager } from "socket.io-client";
import { useParams } from "react-router-dom";
import "./App.css";
import piano from "./audios/piano.mp3";
import simulacro from "./audios/simulacro.mp3";
import sismica from "./audios/sismica.mp3";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DispositivoService from "./service/Dispositivos.service";
import Error from "./page/Error";
import { DateTime } from "luxon";
import Start from "./page/Start";

const MySwal = withReactContent(Swal);

const Device = () => {
  const { id } = useParams();
  const [dispositivo, setDispositivo] = useState(null);
  const [error, setError] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isPlaying, setIsPlaying] = useState(false);
  const [status, setStatus] = useState(false);

  const audioRefPiano = useRef(null);
  const audioRefSimulacro = useRef(null);
  const audioRefSismica = useRef(null);

  useEffect(() => {
    getDispositivo();
  }, []);

  const getDispositivo = async () => {
    try {
      const data = await DispositivoService.getOne(id);
      console.log(data);
      setDispositivo(data);
    } catch (error) {
      console.log(error);
      setError(true);
    }
  };

  const handlePlayPause = () => {
    audioRefPiano.current?.pause();
    audioRefSimulacro.current?.pause();
    audioRefSismica.current?.pause();

    audioRefPiano.current.currentTime = 0;
    audioRefSimulacro.current.currentTime = 0;
    audioRefSismica.current.currentTime = 0;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(DateTime.local().setZone("America/Mexico_City"));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const manager = new Manager(
      // `http://52.21.113.80:8080/dispositivos/socket.io.js`,
      `https://api.juresca.app/dispositivos/socket.io.js`,
      {
        path: "/dispositivos",
        extraHeaders: {
          holaMundo: "mundo",
          dataConnection: id,
        },
      }
    );

    const socket = manager.socket("/");

    socket.on("connect", () => {
      console.log("Conectado al servidor de Socket.IO");
      setStatus(true);
    });

    socket.on("mensajes", (data) => {
      console.log(data);
      const destino = data?.alerta?.id;

      if (destino === "todos") {
        if (
          id === "83713d5d-ca92-4384-bdc8-ea0da1f1abb5" ||
          id === "5cb42982-075f-445e-a9fc-7600e01f3345"
        ) {
          return;
        }
        handlePlayPause();
        audioRefSismica.current?.play();
        MySwal.fire({
          icon: "warning",
          title: "Alerta sismica",
          text: "Evacue las instalaciones con cuidado y medidas preventivas",
          showConfirmButton: false,
          confirmButtonText: "Detener",
          confirmButtonColor: "#ff4603",
          timer: 60000,
        }).then(() => handlePlayPause());
      }

      if (destino === id) {
        console.log(data?.alerta?.tipo);

        if (data?.alerta?.tipo === "Mensaje silencioso") {
          handlePlayPause();
          MySwal.fire({
            icon: "info",
            title: "Mensaje desde el servidor",
            text: "Mensaje prueba emitido por el servidor",
            showConfirmButton: true,
            timer: 60000,
          }).then(() => handlePlayPause());
        }

        if (data?.alerta?.tipo === "Prueba de sonido") {
          handlePlayPause();
          audioRefPiano.current.play();
          MySwal.fire({
            icon: "warning",
            title: "Prueba de sonido",
            text: "Prueba de sonido, pueda cancelar picando la pantalla",
            showConfirmButton: true,
            confirmButtonText: "Detener",
            confirmButtonColor: "#ff4603",
            timer: 60000,
          }).then(() => handlePlayPause());
        }

        if (data?.alerta?.tipo === "Simulacro Alerta") {
          handlePlayPause();
          audioRefSimulacro.current?.play();
          MySwal.fire({
            icon: "warning",
            title: "Simulacro",
            text: "Evacue las instalaciones con cuidado y medidas preventivas",
            showConfirmButton: false,
            timer: 60000,
            confirmButtonText: "Detener",
            confirmButtonColor: "#ff4603",
            backdrop: `
            url("https://upload.wikimedia.org/wikipedia/commons/e/eb/Blinking_warning.gif")
          `,
          }).then(() => handlePlayPause());
        }

        if (data?.alerta?.tipo === "a alto") {
          handlePlayPause();
        }

        if (data?.alerta?.tipo === "a reinicio") {
          window.location.reload(false);
        }
      }
    });

    socket.on("disconnect", () => {
      console.log("¡Desconectado del servidor!");
      setStatus(false);
    });

    return () => {
      socket.disconnect(); // Desconectar el socket cuando el componente se desmonte
    };
  }, []);

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    return `${day} de ${month} del ${year}`;
  };

  return (
    <>
      {error && <Error />}
      {dispositivo && (
        <>
          <div className="app">
            <div className="row">
              <div className="column">
                <div className="centered-container">
                  <img src={"./logo1.jpg"} alt="Imagen 1" />
                </div>
              </div>
              <div className="column">
                <div className="centered-container">
                  <h2 style={{ marginBottom: 0 }}>{dispositivo?.name}</h2>
                  <span style={{ marginBottom: 10 }}>
                    {id === "83713d5d-ca92-4384-bdc8-ea0da1f1abb5" ||
                    id === "5cb42982-075f-445e-a9fc-7600e01f3345"
                      ? "Queretaro"
                      : "CDMX"}
                  </span>
                  {/* <p>{currentTime.toLocaleString(DateTime.TIME_24_SIMPLE)}</p> */}

                  {/* <p>{formatDate(currentDate)}</p> */}
                  <h4 style={{ color: `${status ? "green" : "red"}` }}>
                    <span>{status ? "Conectado" : "Desconectado"}</span>
                  </h4>

                  <audio ref={audioRefPiano} controls={false}>
                    <source src={piano} type="audio/mpeg" />
                  </audio>
                  <audio ref={audioRefSimulacro} controls={false}>
                    <source src={simulacro} type="audio/mpeg" />
                  </audio>
                  <audio ref={audioRefSismica} controls={false}>
                    <source src={sismica} type="audio/mpeg" />
                  </audio>
                </div>
              </div>
              <div className="column">
                <div className="centered-container">
                  <img
                    src={
                      dispositivo.usuario.fullname === "Loteria"
                        ? "https://www.gob.mx/cms/uploads/press/main_image/190139/post_Logotipo_lotenal.jpg"
                        : "./innova.png"
                    }
                    alt="Imagen 3"
                  />
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="column">
                <div className="centered-container">
                  <img
                    src={"./water.png"}
                    wi
                    dth={90}
                    height={90}
                    alt="Imagen 4"
                  />
                </div>
              </div>
              <div className="column">
                <div className="centered-container">
                  <h4 style={{ color: `${status ? "green" : "red"}` }}>
                    <span>{status ? "Conectado" : "Desconectado"}</span>
                  </h4>

                  <audio ref={audioRefPiano} controls={false}>
                    <source src={piano} type="audio/mpeg" />
                  </audio>
                  <audio ref={audioRefSimulacro} controls={false}>
                    <source src={simulacro} type="audio/mpeg" />
                  </audio>
                  <audio ref={audioRefSismica} controls={false}>
                    <source src={sismica} type="audio/mpeg" />
                  </audio>
                </div>
              </div>
              <div className="column">
                <div className="centered-container">
                  <img
                    src={"./Cerrada.png"}
                    width={80}
                    height={80}
                    alt="Imagen 6"
                  />
                </div>
              </div>
            </div> */}
          </div>
        </>
      )}

      {/* {!isPlaying && (
        <div onClick={() => setIsPlaying(true)}>
          <Start />
        </div>
      )} */}
    </>
  );
};

export default Device;
